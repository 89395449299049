import {ADMIN, COMMERCIAL, COORDINATOR, SUPER_ADMIN, SUPERVISOR, USER} from "@/helpers/constant/roles";
import Store from "@/state/store";

const userRole = Store.getters["auth/getRole"]
const userId = Store.getters["auth/userId"]

export const globalMixing = {
    roles: {
        IS_USER: userRole === USER,
        IS_ADMIN: userRole === ADMIN,
        IS_SUPER_ADMIN: userRole === SUPER_ADMIN,
        IS_COMMERCIAL: userRole === COMMERCIAL,
        IS_COORDINATOR: userRole === COORDINATOR,
        IS_SUPERVISOR: userRole === SUPERVISOR,
    },
    userId
}
