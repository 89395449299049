// import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// const options = {
//     // You can set your default options here
// };


const NotificationPlugin = {
    // install(Vue) {
    //     // Your plugin logic goes here
    //     Vue.use(Toast, options)
    // }
};
export default NotificationPlugin;