import store from "@/state/store";
import {ADMIN, COMMERCIAL, COORDINATOR, SUPER_ADMIN, SUPERVISOR} from "@/helpers/constant/roles";

export default  [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    const role = store.getters["auth/getRole"]
                    // Redirect to the home page instead
                    if (role === COMMERCIAL)
                        next({ name: "commercial-dashboard" });
                    else if (role === SUPERVISOR)
                        next({ name: "supervisor-dashboard" });
                    else if (role === COORDINATOR)
                        next({ name: "coordinator-dashboard" });
                    else if (role === ADMIN || role === SUPER_ADMIN)
                        next({ name: "admin-dashboard" });
                    else
                        next({name: "logout"})
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout", authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                store.commit('auth/LOGOUT_USER')
                next({ name: "login" });
            },
        },
        component: () => import("../views/auth/logout/basic")
    },
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: () => import("../views/account/register.vue"),
    //     meta: {
    //         title: "Register",
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters["auth/loggedIn"]) {
    //                 // Redirect to the home page instead
    //                 next({ name: "default" });
    //             } else {
    //                 // Continue to the login page
    //                 next();
    //             }
    //         },
    //     },
    // },
    // {
    //     path: "/forgot-password",
    //     name: "Forgot password",
    //     component: () => import("../views/account/forgot-password.vue"),
    //     meta: {
    //         title: "Forgot Password",
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters["auth/loggedIn"]) {
    //                 // Redirect to the home page instead
    //                 next({ name: "default" });
    //             } else {
    //                 // Continue to the login page
    //                 next();
    //             }
    //         },
    //     },
    // },
]