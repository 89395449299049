const home = [
    {
        path: "/coordinator/dashboard",
        name: "coordinator-dashboard",
        meta: {
            title: "Coordinator dashboard",
        },
        component: () => import("../pages/coordinator/home.vue"),
    },
]

const users = [
    {
        path: "/coordinator/supervisors",
        name: "coordinator-supervisor-list",
        meta: {
            title: "Superviseurs",
        },
        component: () => import("../pages/coordinator/users/supervisor.vue")
    },
    {
        path: "/coordinator/supervisors/:id",
        name: "coordinator-supervisor-detail",
        meta: {
            title: "Superviseur",
        },
        component: () => import("../pages/admin/users/single-supervisor.vue")
    },
    {
        path: "/coordinator/commercials",
        name: "coordinator-commercial-list",
        meta: {
            title: "Commerciaux",
        },
        component: () => import("../pages/coordinator/users/commercial.vue")
    },
    {
        path: "/coordinator/commercials/:id",
        name: "coordinator-commercial-detail",
        meta: {
            title: "Commercial",
        },
        component: () => import("../pages/admin/users/single-commercial.vue")
    },
    {
        path: "/coordinator/members",
        name: "coordinator-member-list",
        meta: {
            title: "Membres",
        },
        component: () => import("../pages/coordinator/users/member.vue")
    },
    {
        path: "/coordinator/members/:id",
        name: "coordinator-member-detail",
        meta: {
            title: "Membre",
        },
        component: () => import("../pages/admin/users/single-member.vue")
    },
    {
        path: "/coordinator/accounts",
        name: "coordinator-account-list",
        meta: {
            title: "Comptes",
        },
        component: () => import("../pages/coordinator/account/account.vue")
    },
    {
        path: "/coordinator/accounts/:id",
        name: "coordinator-account-detail",
        meta: {
            title: "Compte",
        },
        component: () => import("../pages/admin/account/single-account.vue")
    }
]

const transactions = [
    {
        path: "/coordinator/transactions/subscriptions",
        name: "coordinator-transactions-subscriptions",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/coordinator/transaction/subscription-transaction.vue"),
    },
    {
        path: "/coordinator/transactions/savings",
        name: "coordinator-transactions-savings",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/coordinator/transaction/saving-transaction.vue"),
    },
    {
        path: "/coordinator/transactions/failed",
        name: "coordinator-transactions-failed",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/coordinator/transaction/failed-transaction.vue"),
    },
]

const profile = [
    {
        path: "/coordinator/profile",
        name: "supervisor-profile",
        meta: {
            title: "Profile",
            authRequired: true,
        },
        component: () => import("../pages/coordinator/profile.vue"),
    }

]

const accounts = [
    {
        path: "/supervisor/accounts",
        name: "supervisor-accounts",
        meta: {
            title: "Accounts",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Account.vue"),
    }
]
const coordinatorRoutes = [...home, ...users, ...transactions, ...profile, ...accounts]
coordinatorRoutes.forEach(route => {
    route.meta.coordinatorRequired = true;
    route.meta.authRequired = true;
})
export default [...coordinatorRoutes]