const home = [
    {
        path: "/supervisor/dashboard",
        name: "supervisor-dashboard",
        meta: {
            title: "Supervisor dashboard",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Home.vue"),
    },
]

const commercials = [
    {
        path: "/supervisor/commercials",
        name: "supervisor-commercial-list",
        meta: {
            title: "Commerciaux",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Commercial.vue"),
    },
]

const transactions = [
    {
        path: "/supervisor/transactions/deposits",
        name: "supervisor-transactions-deposit",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Transactions.vue"),
    },
    {
        path: "/supervisor/transactions/withdrawals",
        name: "supervisor-transactions-withdrawals",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Transactions.vue"),
    },
]

const profile = [
    {
        path: "/supervisor/profile",
        name: "supervisor-profile",
        meta: {
            title: "Profile",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Profile.vue"),
    }

]

const accounts = [
    {
        path: "/supervisor/accounts",
        name: "supervisor-accounts",
        meta: {
            title: "Accounts",
            authRequired: true,
        },
        component: () => import("../pages/supervisor/Account.vue"),
    }
]

export default [...home, ...commercials, ...transactions, ...profile, ...accounts]