const home = [
    {
        path: "/commercial/dashboard",
        name: "commercial-dashboard",
        meta: {
            title: "Commercial",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Home.vue"),
    }
]

const members = [
    {
        path: "/commercial/members",
        name: "member-list",
        meta: {
            title: "Commercial",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Member.vue"),
    },
]

const transactions = [
    {
        path: "/commercial/transactions/deposits",
        name: "commercial-transactions-deposits",
        meta: {
            title: "Transactions Entrantes",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Transactions.vue"),
    },
    {
        path: "/commercial/transactions/withdrawals",
        name: "commercial-transactions-withdrawals",
        meta: {
            title: "Transactions Sortantes",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Transactions.vue"),
    },
]

const profile = [
    {
        path: "/commercial/profile",
        name: "commercial-profile",
        meta: {
            title: "Profile",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Profile.vue"),
    }

]

const accounts = [
    {
        path: "/commercial/accounts",
        name: "commercial-accounts",
        meta: {
            title: "Accounts",
            authRequired: true,
        },
        component: () => import("../pages/commercial/Account.vue"),
    }
]

const commercialRoutes = [...home, ...members, ...transactions, ...profile, ...accounts]

commercialRoutes.forEach((route) => {
    route.meta.commercialRequired = true;
    route.meta.authRequired = true;
})
export default [...home, ...members, ...transactions, ...profile, ...accounts]